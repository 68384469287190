<template>
  <div>
    <div class="cotainer-standar text-center">
      <div class="dk-container">
        <span class="d-block font-30 font-res-16 mt-35 text-white font-weight-6 mb-40">BERITA PROVINSI PAPUA BARAT</span>
        <div class="container-standar mt-10 mb-10 pad-20 card shadow">
          <div class="row justify-center">
            <template v-for="(berita, index) in news">
              <router-link :to="`berita/${berita.id}`" v-bind:key="index">
                <figure class="snip1253">
                  <div class="image">
                    <img
                      :src="berita.picture_path"
                      alt="image"
                    />
                  </div>
                  <figcaption>
                    <div class="date">
                      <span class="day">{{ berita.publish_date | publish_date_news('date') }}</span>
                      <span class="month">{{ berita.publish_date | publish_date_news('month') }}</span>
                    </div>
                    <h3>{{ berita.title }}</h3>
                    <p>{{ berita.content | shotDescription }}</p>
                  </figcaption>
                </figure>
              </router-link>
            </template>

            <div class="col-md-12 text-center mt-10" v-if="news.length != allnews.length">
              <span @click="news = allnews"
                class="bg-primary pt-2 pb-2 btn-responsive-home opensans font-weight-6 text-white margin-auto width-30 text-center d-block cursor-pointer font-20 border-radius-5"
              >Show All</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      news: [],
      allnews: []
    };
  },
  mounted() {
    this.$store.commit("SET_BG", true);
    this.getBerita()
  },
  methods: {
    getBerita() {
      this.$axios.get('/news')
        .then(response => {
          this.allnews = response.data
          this.news = response.data.filter( (value, index) => index < 4 )
        })
    }
  }
};
</script>